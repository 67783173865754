// extracted by mini-css-extract-plugin
export var button = "akcja-gorzka-winner-form-module--button--b6f8e";
export var container = "akcja-gorzka-winner-form-module--container--77f5e";
export var error = "akcja-gorzka-winner-form-module--error--40736";
export var errorBlink = "akcja-gorzka-winner-form-module--error-blink--b6e03";
export var fields = "akcja-gorzka-winner-form-module--fields--1e9cf";
export var full = "akcja-gorzka-winner-form-module--full--49114";
export var header = "akcja-gorzka-winner-form-module--header--98de6";
export var icon = "akcja-gorzka-winner-form-module--icon--46522";
export var info = "akcja-gorzka-winner-form-module--info--291c2";
export var input = "akcja-gorzka-winner-form-module--input--07dd7";
export var loading = "akcja-gorzka-winner-form-module--loading--7c374";
export var title = "akcja-gorzka-winner-form-module--title--59d46";