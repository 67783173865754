import React, { useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage, Field, FormikConfig } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';

import {
    container,
    header,
    title,
    info,
    icon,
    input,
    error,
    loading,
    button,
    fields,
    full,
} from './akcja-gorzka-winner-form.module.scss';

import WinnerIcon from '../../assets/images/svg/winner.svg';

import pagesContext from '../../config/pages-context';
import {
    getInitialValues,
    getValidationSchema,
    IAkcjaGorzkaWinnerValues,
} from '../../formik/akcja-gorzka-form-winner.config';
import { getAkcjaGorzkaCheckToken, postAkcjaGorzkaWinner } from '../../api-ssr/akcja-gorzka';
import useContestTokenCheck from '../../hooks/use-contest-token-check';

import Button from '../atoms/button';
import Captcha from '../atoms/captcha';
import Loader from '../atoms/loader';

const RECAPTCHA = process.env.RECAPTCHA;

interface IAkcjaGorzkaWinnerFormProps {
    className?: string;
}

const AkcjaGorzkaWinnerForm: React.FC<IAkcjaGorzkaWinnerFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isTokenCheckLoading, setIsTokenCheckLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync, isSuccess } = useMutation(postAkcjaGorzkaWinner);

    const onLoad = () => {
        setIsTokenCheckLoading(false);
    };

    const token = useContestTokenCheck(language, getAkcjaGorzkaCheckToken, redirectPages, onLoad);

    const handleSubmit: FormikConfig<IAkcjaGorzkaWinnerValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
        } catch (error: any) {
            const errorMessage = error?.messages?.map((message: any) => message.content).join(' ');
            alert(errorMessage || error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const resultsUrl = getPathFromSlug(pagesContext.akcjaGorzkaThankYou.slug, language);
            navigate(`${resultsUrl}?from=winner`);
        }
    }, [isSuccess]);

    if (isTokenCheckLoading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={getInitialValues(token)}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
            enableReinitialize={true}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <div className={header}>
                        <WinnerIcon className={icon} />
                        <h1 className={title}>{t('akcja.gorzka.winner.form.title')}</h1>
                        <p className={info}>{t('akcja.gorzka.winner.form.info')}</p>
                    </div>
                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('akcja.gorzka.winner.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('akcja.gorzka.winner.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('akcja.gorzka.winner.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                type="tel"
                                name="phone"
                                placeholder={t('akcja.gorzka.winner.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="city"
                                placeholder={t('akcja.gorzka.winner.form.city.placeholder')}
                            />
                            <ErrorMessage name="city" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('akcja.gorzka.winner.form.zipCode.placeholder')}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('akcja.gorzka.winner.form.street.placeholder')}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t(
                                    'akcja.gorzka.winner.form.apartmentNumber.placeholder'
                                )}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                        <div className={full}>
                            <Field
                                className={input}
                                name="shop"
                                placeholder={t('akcja.gorzka.winner.form.shop.placeholder')}
                            />
                            <ErrorMessage name="shop" className={error} component="p" />
                        </div>
                    </div>
                    <Button type="submit" className={button}>
                        {t('akcja.gorzka.winner.form.submit')}
                    </Button>
                    {RECAPTCHA && <Captcha siteKey={RECAPTCHA} />}
                </Form>
            )}
        </Formik>
    );
};

const redirectPages = {
    default: pagesContext.akcjaGorzka.slug,
    expired: pagesContext.akcjaGorzkaExpired.slug,
};

export default AkcjaGorzkaWinnerForm;
